import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  requestProgramEnrollmentsList,
  receiveProgramEnrollmentsList,
  handleProgramEnrollmentsListError,
} from './actions';

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROGRAM_ENROLLMENT_LIST_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.PROGRAM_ENROLLMENT_LIST_RECEIVE:
      return {
        ...state,
        request: false,
        data: action.payload,
      };
    case TYPES.PROGRAM_ENROLLMENT_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_PROGRAM_ENROLLMENT_LIST:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchProgramEnrollmentsResults = (careerArea = 'all', params) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestProgramEnrollmentsList());
  const {page = 1, sort = 'program_asc', pageSize = 10, filters = []} =
    params || {};

  let URI = `${
    API.administration.emerging_talent
  }?program_enrollments=true&page=${page}&page_size=${pageSize}&sort=${sort}&career_area=${careerArea}`;

  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      URI += `&${filter.key}=${filter.value}`;
    });
  }

  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProgramEnrollmentsList(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleProgramEnrollmentsListError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleProgramEnrollmentsListError(err));
  }
};
